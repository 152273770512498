@import url("https://fonts.googleapis.com/css?family=Kanit:300");
body {
  font-family: "Kanit" !important;
}

form.ng-submitted .ng-invalid,
form.ng-submitted .ng-invalid {
  border-color: #dd4b39;
}

.no-menu {
  margin-left: 0;
}

.auto-width .modal-dialog {
  width: auto;
  display: table;
}

a.close-button {
  position: absolute;
  right: 10px;
  color: white;
  font-weight: bold;
}

@media screen and (min-width: 1320px) {
  .banner2-box {
    display: inline-table;
    width: 1024px;
  }
  .banner2-box .banner2-img {
    display: inline-block;
    width: 256px;
    height: 256px;
    margin: 0px;
    outline: none;
    *display: inline;
    zoom: 1;
    vertical-align: top;
    line-height: 0;
  }
  .banner2-box img {
    width: 256px;
    height: 256px;
  }
}

@media screen and (max-width: 1319px) {
  .banner2-box {
    display: inline-table;
    width: 100%;
  }
  .banner2-box .banner2-img {
    display: inline-block;
    width: 25%;
    height: 25%;
    margin: 0px;
    outline: none;
    *display: inline;
    zoom: 1;
    vertical-align: top;
    line-height: 0;
  }
  .banner2-box .banner2-img img {
    width: 100%;
    height: 100%;
  }
}

.banner2-box:hover .banner2-img {
  -webkit-filter: blur(2px) grayscale(100%);
  filter: blur(2px) gray;
}

.banner2-box:hover .banner2-img:hover {
  filter: initial;
}

.product-img-box {
  border: 1px solid #e5e5e5;
}

.file-input {
  opacity: 0;
  position: absolute;
}
